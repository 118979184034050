@import '../../partials/variable';
@import '../../partials/mixin';
@import '../../partials/carousel';
::selection {
    background-color: $primary0;
}

#wrapper {
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: row;
    color: white;
    font-family: 'Istok Web', sans-serif;
    font-weight: 100;
    height: 100%;
    #diamond {
        width: 0;
        height: 0;
        position: absolute;
        top: 10%;
        z-index: 10;
        border: 40px solid transparent;
        border-bottom-color: $secondary1;
        &:after {
            content: '';
            position: absolute;
            left: -40px;
            top: 40px;
            width: 0;
            height: 0;
            border: 40px solid transparent;
            border-top-color: $secondary1;
        }
        @include responsive(tablet, landscape) {
            left: 47%;
        }
        @include responsive(tablet, portrait) {
            display: none;
        }
    }
    #login_container {
        width: 50vw;
        background-color: $secondary1;
        position: relative;
        z-index: 20;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .login_back {
            display: flex;
            flex-direction: column;
            padding: 0 20%;
            @include responsive(phone, landscape) {
                padding: 0 10%;
            }
            @include responsive(phone, portrait) {
                padding: 0 10%;
            }
            .logo-servtv6 {
                width: 60%;
                margin-bottom: 10%;
                .logo {
                    width: 100%;
                    height: auto;
                }
                @include responsive(phone, landscape) {
                    margin: 5% 0;
                    width: 35%;
                }
                @include responsive(phone, portrait) {
                    align-self: center;
                }
            }
            #invalid {
                p {
                    padding: 2% 2% 2% 0;
                    font-size: 1rem;
                    text-decoration: underline;
                    color: $warning;
                }
            }
            #login {
                display: flex;
                flex-direction: column;
                margin-bottom: 3%;
                label,
                input {
                    padding: 1% 0
                }
                input {
                    @include responsive(phone, landscape) {
                        margin: 0;
                    }
                }
                .login_submit {
                    color: white;
                    border: 1px solid white;
                    border-radius: 10px;
                    padding: 10px 20px;
                    max-width: 30%;
                    background: $primary0;
                    transition: all 0.3s ease;
                    @include responsive(phone, portrait) {
                        max-width: 75%;
                    }
                    &:hover {
                        border: 1px solid $primary0;
                        background: white;
                        color: $primary0;
                    }
                }
                @include responsive(phone, landscape) {
                    padding: 0;
                }
                @include responsive(phone, portrait) {
                    min-width: 50%;
                }
            }
            .mail,
            .tel {
                color: white;
                text-decoration: none;
                transition: all 0.3s ease;
                &:hover {
                    color: $primary0;
                }
            }
            .tel {
                font-weight: 700;
            }
            @include responsive(tablet, portrait) {
                width: 100vw;
            }
            @include responsive(phone, landscape) {
                width: 100vw;
            }
        }
    }
    footer {
        padding-bottom: 2%;
        position: absolute;
        bottom: 0;
        width: 100%;
        hr {
            @include responsive(phone, landscape) {
                display: none;
            }
        }
        .no-account {
            padding: 2% 0 0 20%;
            @include responsive(phone, landscape) {
                position: relative;
                padding: 0;
                margin: 0;
                left: 65%;
            }
            @include responsive(phone, portrait) {
                padding-left: 10%;
            }
        }
        .signup {
            margin: 2% 0 0 20%;
            color: white;
            font-weight: 700;
            text-decoration: none;
            @include responsive(phone, landscape) {
                left: 80%;
                position: relative;
                padding: 0;
                margin: 0;
            }
            @include responsive(phone, portrait) {
                margin-left: 10%;
            }
        }
    }
    @include responsive(phone, landscape) {
        padding-bottom: 0;
    }
}
